<template lang="pug">
.container
  b-loading(:is-full-page='false' v-model='isLoading')
  page-title(title='Reglas de tiempos')
  .card
    .card-content
      app-form(@submit='submit' :is-saving='isSaving' :horizontal='false')
        rule-base.mb-3(v-model='ruleBase' name-placeholder='Notificar SOAT')
        .card.mb-3
          .card-content
            div
              b-radio(v-model='timeRule.type' name='type' :native-value='ruleOdometerTypeEnum.OneTime' @input='typeChanged') Una sola vez
              b-radio(v-model='timeRule.type' name='type' :native-value='ruleOdometerTypeEnum.Repetitive' @input='typeChanged') Repetitivo
            div.mt-5(v-if='typeIsSelected')
              date-time-field(label='La regla se ejecutará el...' v-model='timeRule.expirationDate' required v-if='timeRule.type === ruleOdometerTypeEnum.OneTime' :message='expirationDateMessage')
              .two-columns-fields
                text-field.mt-4(label='Cada' placeholder='10' type='number' required v-model='timeRule.frequency' v-if='timeRule.type === ruleOdometerTypeEnum.Repetitive')
                select-field(label='Frecuencia' required v-model.number='timeRule.frequencyType' :enum='ruleTimeFrequencyTypeEnum' :enum-names='ruleTimeFrequencyTypeDescriptions'  v-if='timeRule.type === ruleOdometerTypeEnum.Repetitive')
              date-time-field.mt-4(label='A partir del' required v-model='timeRule.start' v-if='timeRule.type === ruleOdometerTypeEnum.Repetitive')
              text-field.mt-4(label='Cuántas veces: (Cero es indefinido)' placeholder='0' type='number' required v-model='timeRule.times' v-if='timeRule.type === ruleOdometerTypeEnum.Repetitive' min='0')
              text-field.mt-4(label='Mensaje de la notificación' placeholder='Ejm: Es momen to de cambiar aceite' :maxlength='50' required v-model='timeRule.message')
        rule-output.mb-3(v-model='ruleOutputs')
</template>

<script>
import { ruleTypeEnum, ruleOdometerTypeEnum, ruleTimeFrequencyTypeEnum, ruleTimeFrequencyTypeDescriptions } from '@/constants/enums'
import toastService from '@/services/toast.service'
import rulesService from '@/services/rules.service'
import dateDistanceFilter from '@/filters/dateDistance.filter'
import { rulesList } from '@/router/routes'

import RuleBase from './blocks/RuleBase'
import RuleOutput from './blocks/RuleOutput'

export default {
  components: { RuleBase, RuleOutput },
  data () {
    return {
      dateDistanceFilter,
      ruleTimeFrequencyTypeEnum,
      ruleTimeFrequencyTypeDescriptions,
      ruleOdometerTypeEnum,
      ruleBase: {
        type: ruleTypeEnum.Time,
        name: '',
        devices: []
      },
      ruleOutputs: {},
      timeRule: {
        message: '',
        frequency: 1,
        frequencyType: 1,
        expirationDate: null
      },
      isLoading: false,
      isSaving: false
    }
  },
  created () {
    this.getData()
  },
  computed: {
    typeIsSelected () {
      return this.timeRule.type || this.timeRule.type === 0
    },
    expirationDateMessage () {
      return this.timeRule.expirationDate ? [dateDistanceFilter(this.timeRule.expirationDate, true)] : []
    }
  },
  methods: {
    async getData () {
      this.isLoading = true

      if (this.$route.params.id) {
        const promises = [rulesService.getOne(this.$route.params.id)]
        const [ruleResult] = await Promise.all(promises)
        if (ruleResult) {
          const { id, type, name, devices, emailActions, generateEvent, turnOffEngine, timeRules } = ruleResult
          this.ruleBase = { id, type, name, devices }
          const timeRule = timeRules[0] || {}

          if (timeRule.frequencyType === ruleTimeFrequencyTypeEnum.OneTime) {
            timeRule.type = ruleOdometerTypeEnum.OneTime
          } else timeRule.type = ruleOdometerTypeEnum.Repetitive
          this.timeRule = timeRule
          this.ruleOutputs = { emailActions, generateEvent, turnOffEngine }
        }
      }
      this.isLoading = false
    },
    typeChanged () {
      if (this.timeRule.type === ruleOdometerTypeEnum.OneTime) {
        this.timeRule.frequency = null
        this.timeRule.frequencyType = ruleTimeFrequencyTypeEnum.OneTime
        this.timeRule.start = null
        this.timeRule.times = null
      } else {
        this.timeRule.expirationDate = null
        this.timeRule.frequencyType = ruleTimeFrequencyTypeEnum.Month
      }
    },
    async submit () {
      const timeRules = [this.timeRule]
      const rule = {
        ...this.ruleBase,
        ...this.ruleOutputs,
        timeRules
      }
      this.isSaving = true
      const result = await rulesService.save(rule)
      if (result) {
        toastService.show('Regla guardada')
        this.$router.push({ name: rulesList.name })
      }
      this.isSaving = false
    }
  }
}
</script>

<style lang="scss" scoped>
.two-columns-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  align-items: center;
}
</style>
